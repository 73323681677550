const ua = {
  'welcomeScreenHeading': 'Ласкаво просимо',
  'requiredCheckboxMessage': 'Будь ласка, підтвердіть чекбокс',
  'pleaseSelect': 'Будь ласка, виберіть…',
  'cancel': 'Скасувати',
  'ok': 'OK',
  'yes': 'Так',
  'back': 'Назад',
  'welcome': 'Ласкаво просимо',
  'no': 'Ні',
  'pleaseEnter': 'Будь ласка, введіть…',
  'thanksForYourCheckin': 'Дякуємо за реєстрацію.',
  'checkoutConfirmTitle': 'Підтвердити вихід',
  'checkoutConfirmMessage': 'Будь ласка, виходьте тільки після завершення вашого візиту.',
  'yesCheckout': 'Зараз вийти',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Дякуємо за ваш візит.',
  'youCanCloseThisPageNow': 'Ви можете закрити цю сторінку.',
  'checkedOutViewTitle': 'Ви вийшли',
  'checkoutMessage': 'Ми вас виводимо…',
  'checkout': 'Вийти',
  'invalidNameFormat': 'Будь ласка, введіть повне ім\'я.',
  'clickToSign': 'натисніть, щоб підписати',
  'invalidQrCodeHeading': 'Недійсний QR-код',
  'invalidQrCodeText': 'Будь ласка, зверніться до рецепції.',
  'checkedOut': 'Вийшли',
  'checkinDeniedHeading': 'На жаль, ми не можемо вас зареєструвати.',
  'next': 'Далі',
  'groupSelectionHeading': 'Виберіть групу відвідувачів:',
  'checkinMessage': 'Ми вас реєструємо…',
  'aServiceOf': 'Сервіс від',
  'checkedIn': 'Зареєстровані',
  'CheckedInInfo': 'Будь ласка, залиште цю сторінку відкритою, щоб вийти після завершення вашого візиту.',
  'checkin': 'Зареєструватися',
  'delete': 'Видалити',
  'pleaseCheckYourInput': 'Будь ласка, перевірте ваші введені дані',
  'signAndProceed': 'Підписати та продовжити',
  'signatureRequiredMessage': 'Будь ласка, підпишіть документ',
  'requiredField': 'Обов\'язкове поле',
  'invalidEmailMessage': 'Будь ласка, введіть дійсну адресу електронної пошти',
  'pleaseEnterANumber': 'Будь ласка, введіть число',
  'pleaseEnterAValidNumber': 'Будь ласка, введіть дійсне ціле число',
  'invalidUrlMessage': 'Будь ласка, введіть дійсну URL, напр. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Ми не можемо вас зареєструвати в даний момент. Будь ласка, зверніться до рецепції.',
  'checkinLoading': 'Реєстрація…',
  'functionsError.denyOnValidationFailed': 'Ми не можемо вас зареєструвати через ваші дані. Будь ласка, зверніться до рецепції.',
  'functionsError.missingRequiredInput': 'Оскільки ви не заповнили всі обов\'язкові поля, ми не можемо вас зареєструвати. Будь ласка, зверніться до рецепції.',
  'functionsError.invalidInputData': 'Ви ввели недійсні дані. Будь ласка, зверніться до рецепції',
  'functionsError.checkinTokenExpired': 'Ваш лінк для реєстрації закінчився. Будь ласка, відскануйте QR-код знову або зверніться до рецепції.',
  'startCamera': 'Натисніть, щоб активувати камеру.',
  'cameraLoading': 'Завантаження…',
  'photoSectionTitle': 'Будь ласка, зробіть фото для реєстрації.',
  'photoSectionDescription': 'Доступ до камери та фото використовуються виключно для підтвердження вашої особистості для реєстрації.',
  'takePhoto': 'Зробити фото',
  'reTakePhoto': 'Зробити ще раз',
  'cameraForbidden': 'Доступ до камери заборонено.',
  'fullname': 'Повне ім\'я',
  'Details': 'Деталі',
  'visitorIdCardWillBePrinted': 'Ваш гостьовий пропуск друкується ...',
  'visitorIdCardHasBeenPrinted': 'Ваш гостьовий пропуск надруковано!',
  'download': 'Завантаження',
};

export { ua };

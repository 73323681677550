const si = {
  'welcomeScreenHeading': 'Dobrodošli',
  'requiredCheckboxMessage': 'Prosimo, potrdite polje',
  'pleaseSelect': 'Prosim, izberite…',
  'cancel': 'Prekliči',
  'ok': 'V redu',
  'yes':'Da',
  'back': 'Nazaj',
  'welcome': 'Dobrodošli',
  'no':'Ne',
  'pleaseEnter': 'Prosim, vnesite…',
  'thanksForYourCheckin': 'Hvala za vašo prijavo.',
  'checkoutConfirmTitle': 'Potrditev odjave',
  'checkoutConfirmMessage': 'Prosim, odjavite se šele, ko končate svoj obisk.',
  'yesCheckout': 'Odjava zdaj',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Hvala za vaš obisk.',
  'youCanCloseThisPageNow': 'Zdaj lahko zaprete to stran.',
  'checkedOutViewTitle': 'Odjavljeni ste',
  'checkoutMessage': 'Odjavljamo vas…',
  'checkout': 'Odjava',
  'invalidNameFormat': 'Prosimo, navedite polno ime.',
  'clickToSign': 'kliknite za podpis',
  'invalidQrCodeHeading': 'Neveljaven QR-koda',
  'invalidQrCodeText': 'Prosimo, obrnite se na sprejemno osebje.',
  'checkedOut': 'Odjavljeni',
  'checkinDeniedHeading': 'Žal vas ne moremo prijaviti.',
  'next': 'Naprej',
  'groupSelectionHeading': 'Izberite skupino obiskovalcev:',
  'checkinMessage': 'Prijavljamo vas…',
  'aServiceOf': 'Storitev od',
  'checkedIn': 'Prijavljeni',
  'CheckedInInfo': 'Prosim, pustite to stran odprto, da se boste po zaključku obiska odjavili.',
  'checkin': 'Prijava',
  'delete': 'Izbriši',
  'pleaseCheckYourInput': 'Prosim, preverite svoje vnose',
  'signAndProceed': 'Podpiši in nadaljuj',
  'signatureRequiredMessage': 'Prosimo, podpišite dokument',
  'requiredField': 'Obvezno polje',
  'invalidEmailMessage': 'Prosimo, vnesite veljaven e-poštni naslov',
  'pleaseEnterANumber': 'Prosimo, vnesite številko',
  'pleaseEnterAValidNumber': 'Prosimo, vnesite veljavno celo število',
  'invalidUrlMessage': 'Prosim, vnesite veljaven URL npr. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Trenutno vas ne moremo prijaviti. Prosimo, obrnite se na sprejemno osebje.',
  'checkinLoading': 'Prijavljanje…',
  'functionsError.denyOnValidationFailed': 'Zaradi vaših podatkov vas ne moremo prijaviti. Prosimo, obrnite se na sprejemno osebje.',
  'functionsError.missingRequiredInput': 'Ker niste izpolnili vseh obveznih polj, vas ne moremo prijaviti. Prosimo, obrnite se na sprejemno osebje.',
  'functionsError.invalidInputData': 'Podali ste neveljavne podatke. Prosimo, obrnite se na sprejemno osebje',
  'functionsError.checkinTokenExpired': 'Vaša povezava za prijavo je potekla. Prosimo, ponovno skenirajte QR-kodo ali se obrnite na sprejemno osebje.',
  'startCamera': 'Tapnite za aktiviranje kamere.',
  'cameraLoading': 'Nalaganje…',
  'photoSectionTitle': 'Prosimo, naredite fotografijo za prijavo.',
  'photoSectionDescription': 'Dostop do kamere in fotografije se uporablja izključno za preverjanje vaše identitete za prijavo.',
  'takePhoto': 'Posnemite fotografijo',
  'reTakePhoto': 'Ponovno posnemite',
  'cameraForbidden': 'Dostop do kamere zavrnjen.',
  'fullname': 'Polno ime',
  'Details': 'Podrobnosti',
  'visitorIdCardWillBePrinted': 'Vaša obiskovalčeva izkaznica se tiska ...',
  'visitorIdCardHasBeenPrinted': 'Vaša obiskovalčeva izkaznica je bila natisnjena!',
  'download': 'Prenos',
};

export { si };

const hr = {
  'welcomeScreenHeading': 'Dobrodošli',
  'requiredCheckboxMessage': 'Molimo potvrdite okvir za potvrd',
  'pleaseSelect': 'Molimo odaberite…',
  'cancel': 'Odustani',
  'ok': 'OK',
  'yes': 'Da',
  'back': 'Natrag',
  'welcome': 'Dobrodošli',
  'no': 'Ne',
  'pleaseEnter': 'Molimo unesite…',
  'thanksForYourCheckin': 'Hvala na prijavi.',
  'checkoutConfirmTitle': 'Potvrdite odjavu',
  'checkoutConfirmMessage': 'Molimo odjavite se tek nakon završetka posjeta.',
  'yesCheckout': 'Odjavi se sada',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Hvala na posjetu.',
  'youCanCloseThisPageNow': 'Sada možete zatvoriti ovu stranicu.',
  'checkedOutViewTitle': 'Odjavljeni ste',
  'checkoutMessage': 'Odjavljujemo vas…',
  'checkout': 'Odjavi se',
  'invalidNameFormat': 'Molimo navedite puno ime.',
  'clickToSign': 'kliknite za potpisivanje',
  'invalidQrCodeHeading': 'Nevažeći QR kod',
  'invalidQrCodeText': 'Molimo obratite se osoblju na recepciji.',
  'checkedOut': 'Odjavljen',
  'checkinDeniedHeading': 'Nažalost, ne možemo vas prijaviti.',
  'next': 'Dalje',
  'groupSelectionHeading': 'Odaberite grupu posjetitelja:',
  'checkinMessage': 'Prijavljujemo vas…',
  'aServiceOf': 'Usluga od',
  'checkedIn': 'Prijavljen',
  'CheckedInInfo': 'Molimo ostavite ovu stranicu otvorenom kako biste se odjavili nakon završetka posjeta.',
  'checkin': 'Prijavi se',
  'delete': 'Izbriši',
  'pleaseCheckYourInput': 'Molimo provjerite svoje unose',
  'signAndProceed': 'Potpiši i nastavi',
  'signatureRequiredMessage': 'Molimo potpišite dokument',
  'requiredField': 'Obavezno polje',
  'invalidEmailMessage': 'Molimo unesite valjanu adresu e-pošte',
  'pleaseEnterANumber': 'Molimo unesite broj',
  'pleaseEnterAValidNumber': 'Molimo unesite valjan cijeli broj',
  'invalidUrlMessage': 'Molimo unesite valjanu URL adresu npr. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Trenutno vas ne možemo prijaviti. Molimo obratite se osoblju na recepciji.',
  'checkinLoading': 'Prijava…',
  'functionsError.denyOnValidationFailed': 'Ne možemo vas prijaviti zbog vaših podataka. Molimo obratite se osoblju na recepciji.',
  'functionsError.missingRequiredInput': 'Niste ispunili sva obavezna polja, stoga vas ne možemo prijaviti. Molimo obratite se osoblju na recepciji.',
  'functionsError.invalidInputData': 'Unijeli ste nevažeće podatke. Molimo obratite se osoblju na recepciji',
  'functionsError.checkinTokenExpired': 'Vaša veza za prijavu je istekla. Molimo ponovno skenirajte QR kod ili se obratite osoblju na recepciji.',
  'startCamera': 'Dodirnite za aktivaciju kamere.',
  'cameraLoading': 'Učitavanje…',
  'photoSectionTitle': 'Molimo snimite svoju fotografiju za prijavu.',
  'photoSectionDescription': 'Pristup kameri i fotografiji koristi se isključivo za provjeru vašeg identiteta radi prijave.',
  'takePhoto': 'Snimite fotografiju',
  'reTakePhoto': 'Ponovno snimanje',
  'cameraForbidden': 'Pristup kameri je zabranjen.',
  'fullname': 'Puno ime',
  'Details': 'Detalji',
  'visitorIdCardWillBePrinted': 'Vaša posjetnička iskaznica se ispisuje ...',
  'visitorIdCardHasBeenPrinted': 'Vaša posjetnička iskaznica je ispisana!',
  'download': 'Preuzimanje',
};

export { hr };

const en = {
  'welcomeScreenHeading': 'Welcome!',
  'requiredCheckboxMessage': 'Bitte bestätigen Sie die Checkbox',
  'pleaseSelect': 'Please select…',
  'cancel': 'Cancel',
  'ok': 'OK',
  'next': 'Next',
  'back': 'Back',
  'yes':'Yes',
  'welcome': 'Welcome',
  'no':'No',
  'checkin': 'Checkin',
  'groupSelectionHeading': 'Select a visitor group:',
  'thanksForYourCheckin': 'Thanks for your check-in.',
  'invalidNameFormat': 'Please enter your full name.',
  'checkinMessage': 'We are checking you in…',
  'checkoutMessage': 'We are checking you out…',
  'delete': 'Delete',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'aServiceOf': 'A service of',
  'yesCheckout': 'Check out now',
  'thanksForYourVisit': 'Thanks for your visit.',
  'clickToSign': 'click to sign',
  'youCanCloseThisPageNow': 'You can close this page now.',
  'invalidQrCodeHeading': 'Invalid QR-Code',
  'invalidQrCodeText': 'Please contact the reception.',
  'checkedIn': 'Checked in',
  'checkedOut': 'Checked out',
  'pleaseEnter': 'Please enter…',
  'CheckedInInfo': 'Please keep this page open to check out after your visit.',
  'pleaseCheckYourInput': 'Please check your input',
  'checkedOutViewTitle': 'You are checked out',
  'checkinDeniedHeading': 'We cannot check you in',
  'checkout': 'Sign out',
  'checkoutConfirmTitle': 'Check-out confirm',
  'checkoutConfirmMessage': 'Please check out only after you have finished your visit.',
  'signAndProceed': 'Sign and proceed',
  'signatureRequiredMessage': 'Please sign the document',
  'requiredField': 'Required field',
  'invalidEmailMessage': 'Please enter a valid email address',
  'pleaseEnterANumber': 'Please enter a number',
  'pleaseEnterAValidNumber': 'Please enter a whole number without any special characters',
  'invalidUrlMessage': 'Please enter a valid url e.g. https://www.example.com',
  'functionsError.noSubscriptionFound': 'We cannot check you in at the moment. Please contact the reception.',
  'checkinLoading': 'Checkin…',
  'functionsError.denyOnValidationFailed': 'We cannot check you in due to your given information. Please contact the reception.',
  'functionsError.missingRequiredInput': 'We cannot check you in because you have not filled in all required fields. Please contact the reception.',
  'functionsError.invalidInputData': 'You have made invalid entries. Please contact the reception.',
  'functionsError.checkinTokenExpired': 'Your check-in link has expired. Please scan the QR code again or contact the reception.',
  'startCamera': 'Tap to activate camera.',
  'cameraLoading': 'Loading…',
  'photoSectionTitle': 'Please take a photo of yourself to check in.',
  'photoSectionDescription': 'Access to the camera and the photo will only be used to verify your identity for check-in.',
  'takePhoto': 'Take photo',
  'reTakePhoto': 'Retake photo',
  'cameraForbidden': 'Access to the camera denied.',
  'fullname': 'Fullname',
  'Details': 'Details',
  'visitorIdCardWillBePrinted': 'Your visitor badge is being printed ...',
  'visitorIdCardHasBeenPrinted': 'Your visitor badge has been printed!',
  'download': 'Download',
};

export { en };
